/**
 * Format's price with currency.
 * withDecimals: If true, the price will be formatted with 2 decimals
 * withCurrency: If true, the price will be formatted with currency symbol
 * NOTE: !withDecimals rounds up to the nearest integer
 * README: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#fractiondigits_significantdigits_and_integerdigits
 * showCents: If true, prices under $1 will be displayed as decimal values (e.g., 0.10 instead of $0.10)
 *
 * @returns formatted price string
 */
export function formatPrice(
  price: number | string, // price
  withDecimals = true, // withDecimals format with decimals
  withCurrency = true, // withCurrency format with currency
  showCents = false // showCents display prices under $1 as decimal values
): string {
  const priceValue = typeof price === 'string' ? Number(price) : price

  if (isNaN(priceValue)) return '-'

  // For values under $1 when showCents is true, we need to ensure decimals are shown
  const forceDecimals = showCents && priceValue > 0 && priceValue < 1

  const formatOptions: Intl.NumberFormatOptions = {
    style: withCurrency ? 'currency' : 'decimal',
    currency: 'USD',
    minimumFractionDigits: withDecimals || forceDecimals ? 2 : 0,
    maximumFractionDigits: withDecimals || forceDecimals ? 2 : 0,
  }

  return new Intl.NumberFormat('en-US', formatOptions).format(priceValue)
}
