import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'

import { useTranslations } from 'next-intl'
import { alterCart, getAllCarts } from '@/api/checkout'
import { useCart } from '@/hooks/useCart'
import { useAuthRequestProtection } from '@/hooks/useAuthRequestProtection'
import { openCartModal } from 'apolloCache/cartCache'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function useAddToCart({
  productId,
  quantity = 1,
  redirectToCheckout = false,
  activeModal = false,
}: {
  productId: string
  quantity?: number
  redirectToCheckout?: boolean
  activeModal?: boolean
}): any {
  const t = useTranslations()
  const router = useRouter()
  const toast = useToast()

  const { getCart } = useCart()
  const [alterCartMutation, alterCartMutationData] = useMutation(alterCart, {
    variables: {
      productId,
      quantity,
    },
    refetchQueries: [getAllCarts],
    onCompleted(data) {
      getCart()
      if (data?.alterCart?.boolean && redirectToCheckout) {
        return router.push('/checkout')
      }
      if (data?.alterCart?.boolean && activeModal) {
        openCartModal()
      }
      if (data?.alterCart?.boolean && quantity === -1) {
        return toast({
          title: t('checkout.removeFromCartSuccess'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }

      if (data?.alterCart?.boolean) {
        return toast({
          title: t('checkout.addToCartSuccess'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    onError(error) {
      console.error(error)
    },
  })

  useEffect(() => {
    const error = alterCartMutationData?.error
    if (alterCartMutationData?.error?.message) {
      toast({
        title: error?.message || t('common.default-error'),
        status: 'error',
      })
    }
  }, [alterCartMutationData?.error])

  return [useAuthRequestProtection(alterCartMutation), alterCartMutationData]
}
